<template>
  <div class="v-theme">
    <TheHeading level="h1">Tverrfaglige temaer</TheHeading>

    <div v-if="cards.length" class="v-theme__cards">
      <Card
        v-for="card in cards"
        :key="card.id"
        :path="`/theme/${card.slug}`"
        :title="card.description"
        :pre-heading="card.name"
        :button-text="$t(card.description ? 'ARCHIVE_FAG_BTN_TEXT' : 'COMING')"
        type="archive"
        class="v-theme__card"
        :disabled="!card.description"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onBeforeMount } from 'vue'

import Card from '@/components/Card'
import TheHeading from '@/components/TheHeading'

export default {
  name: 'Home',

  components: {
    Card,
    TheHeading,
  },

  setup() {
    const store = useStore()
    const chapter = computed(() =>
      store.getters['content/getStructuresBySlugPath']('tverrfaglige-temaer'),
    )
    const cards = computed(() => {
      return store.getters['content/getStructuresChildrens'](
        chapter.value.id,
        'DIRECTORY',
      )
    })

    onBeforeMount(async () => {
      await store.dispatch('content/setStructure', 'tverrfaglige-temaer')
      await store.dispatch('content/setStructuresChildren', {
        slugPath: 'tverrfaglige-temaer',
        query: { limit: 100, filter: `{"type": "DIRECTORY"}` },
      })
    })

    return {
      cards,
      chapter,
    }
  },
}
</script>

<style lang="scss">
.v-theme {
  &__cards {
    overflow: auto;
    margin: 2rem -0.5rem;

    @include bp(small) {
      display: flex;
      flex-wrap: wrap;
    }

    + div {
      margin-top: 0;
    }
  }
  &__card {
    display: block;
    margin: 0.5rem;

    @include bp(small) {
      width: calc(50% - 1rem);
    }

    @include bp(medium) {
      width: calc(33% - 1rem);
    }

    @include bp(1320px) {
      width: calc(25% - 1rem);
    }

    .c-card {
      &__pre-heading {
        font-weight: 500;
        font-size: 1rem;
      }
      &__title {
        font-weight: 400;
      }
    }
  }
}
</style>
