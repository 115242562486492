<template>
  <div :class="['c-card', `c-card--${type}`, { 'c-card--disabled': disabled }]">
    <component
      :is="cardWrapperTag"
      class="c-card__link"
      v-bind="cardWrapperAttrs"
      v-on="cardWrapperEvents"
    >
      <div class="c-card__wrapper">
        <div v-if="image" class="c-card__img-outer">
          <img :src="image.url" :alt="image.alt" class="c-card__img" />
        </div>
        <div class="c-card__text">
          <span v-if="preHeading" class="c-card__pre-heading">
            {{ preHeading }}
          </span>
          <TheHeading level="h3" class="c-card__title">
            {{ title }}
          </TheHeading>
          <span v-if="description" class="c-card__description">
            {{ description }}
          </span>
        </div>
        <div v-if="buttonText" class="c-card__btn-wrapper">
          <TheButton class="c-card__btn" :pink="!disabled">
            {{ buttonText }}
          </TheButton>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'

import { getAsset } from '@/services/assets'
import { addCloudinaryUrlParams } from '@/utils'

import TheButton from '@/components/TheButton'
import TheHeading from '@/components/TheHeading'

export default {
  name: 'Card',

  components: {
    TheButton,
    TheHeading,
  },

  props: {
    title: String,
    description: String,
    preHeading: String,
    imageId: String,
    type: {
      type: String,
      default: 'default',
    },
    path: {
      type: String,
      default: '/fag',
    },
    buttonText: String,
    disabled: Boolean,
    action: Function,
  },

  setup(props) {
    const image = ref(null)
    const cardWrapperTag = computed(() => {
      if (props.type === 'archive' || props.type === 'home') {
        return 'router-link'
      }

      if (props.type === 'concept') {
        return 'a'
      }

      return 'div'
    })
    const cardWrapperAttrs = computed(() => {
      if (props.type === 'archive' || props.type === 'home') {
        return {
          to: props.path,
        }
      }

      if (props.type === 'concept') {
        return {
          href: '#',
        }
      }

      return {}
    })
    const cardWrapperEvents = computed(() => {
      if (props.type === 'concept') {
        return {
          click: (ev) => {
            ev.preventDefault()
            props.action()
          },
        }
      }

      return {}
    })

    onMounted(async () => {
      if (props.imageId) {
        image.value = await getAsset(
          process.env.VUE_APP_COSS_NAMESPACE,
          props.imageId,
        )
        image.value.url = addCloudinaryUrlParams(
          image.value.url,
          'w_400,h_126,c_crop',
        )
      }
    })

    return {
      image,
      cardWrapperTag,
      cardWrapperAttrs,
      cardWrapperEvents,
    }
  },
}
</script>

<style lang="scss">
.c-card {
  &__link {
    text-decoration: none;
  }

  &--concept {
    position: relative;
  }

  &--home {
    width: 55%;
    min-width: 195px;
    border-radius: 0.625rem;
    overflow: hidden;

    @include bp(large) {
      width: calc(50% - 2.25rem);
      max-width: 400px;
    }
  }

  &--archive {
    border-radius: 0.625rem;
    overflow: hidden;
  }

  &--disabled {
    cursor: not-allowed;

    &.c-card--archive .c-card__wrapper {
      background: $color-grey;
    }

    .c-card {
      &__link {
        pointer-events: none;
      }
      &__img {
        filter: grayscale(1) brightness(1.5) opacity(0.2);
      }
      &__btn {
        font-weight: 500;
      }
    }
  }
  &__wrapper {
    display: block;
    background: $color-white;

    .c-card--archive & {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background: $color-blue-light;
    }

    .c-card--concept & {
      height: 100%;
      padding-bottom: rem(20px);
    }
  }
  &__img {
    display: block;
  }
  &__pre-heading {
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  &__text {
    padding: 0.9375rem 2.1875rem;

    .c-card--concept & {
      padding: rem(16px);
    }

    .c-card--archive & {
      padding: 2.1875rem 1.25rem;
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;

    .c-card--concept & {
      font-size: rem(24px);
      font-weight: 600;
      margin-bottom: rem(18px);
    }
  }

  &__btn {
    padding: 0.75rem 3.625rem;

    .c-card--concept & {
      padding: 0;
    }

    &-wrapper {
      display: none;

      @include bp(small) {
        display: block;
        text-align: center;
        padding: 1.25rem;
        background: $color-white;
        margin-top: auto;
      }

      .c-card--concept & {
        padding: 0;
        display: block;
        text-align: right;
        position: absolute;
        right: 1.5rem;
        bottom: 0.25rem;
      }
    }

    .c-card--concept & {
      color: $color-text--4;
      font-weight: 700;
      font-size: rem(14px);
      background: none;
    }
  }
}

.c-card__description {
  .c-card--concept & {
    font-size: rem(16px);
    overflow-wrap: break-word;
  }
}
</style>
